import React, { useEffect, useState } from "react";
import Header from "./Header/Header";
import Backend from "../../Backend/Backend";
import { toast } from "react-toast";
import { Dark } from "../../Theme/Theme";
import Loading from "react-loading";
import SymbolCard from "./SymbolCard";
import Menu from "./Menu";
const backend = new Backend();
export default function Cash() {
  const [symbol, setSymbol] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadSymbol();
  }, []);

  const loadSymbol = () => {
    backend.symbol_get_cash().then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setSymbol(r.symbol);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div style={{ padding: 10 }}>
      <Header load={loadSymbol} total={symbol.length} />

      <Menu />

      <div
        className="scroll"
        style={{
          backgroundColor: Dark.background,
          height: "70vh",
          overflowY: "scroll",
          margin: 10,
          borderRadius: 10,
        }}
      >
        {loading ? (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loading type="spin" color={Dark.text} height={30} width={30} />
          </div>
        ) : symbol.length > 0 ? (
          symbol.map((i, t) => {
            return <SymbolCard item={i} load={loadSymbol} />;
          })
        ) : (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ fontSize: 25, marginBottom: 10, color: Dark.text }}>
              {" "}
              Nothing here
            </div>
            <div style={{ color: Dark.text }}>
              Use the search bar to add instruments.
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
