const Dark = {
  background: "#0F0920",
  primary: "#0368FF",
  text: "#F2F0FF",
  secoundry: "#FF3E95",
  hover: "#1b1d22",
  sell : "#FF0000",
  buy : "#399918"
};

const Light = {
  background: "#FFFFFF",
  primary: "#0368FF",
  text: "#303641",
  secoundry: "#FF3E95",
  hover: "#f6f7f8",
  sell : "#FF0000",
  buy : "#399918"
};

export { Dark, Light };
