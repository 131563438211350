import React, { useState } from "react";
import { Dark } from "../Theme/Theme";

export default function Header(props) {
  const type = props.type;
  return (
    <div
      style={{
        height: 50,
        backgroundColor: Dark.background,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          onClick={() => props.update(0)}
          style={{
            fontWeight: "600",
            backgroundColor: type == 0 ? Dark.primary : Dark.hover,
            padding: 7,
            paddingRight: 40,
            paddingLeft: 40,
            borderRadius: 4,
          }}
        >
          NSE-CASH
        </div>
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          onClick={() => props.update(1)}
          style={{
            fontWeight: "600",
            backgroundColor: type == 1 ? Dark.primary : Dark.hover,
            padding: 7,
            paddingRight: 40,
            paddingLeft: 40,
            borderRadius: 4,
          }}
        >
          NSE
        </div>
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          onClick={() => props.update(2)}
          style={{
            fontWeight: "600",
            backgroundColor: type == 2 ? Dark.primary : Dark.hover,
            padding: 7,
            paddingRight: 40,
            paddingLeft: 40,
            borderRadius: 4,
          }}
        >
          MCX
        </div>
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          onClick={() => props.update(3)}
          style={{
            fontWeight: "600",
            backgroundColor: type == 3 ? Dark.primary : Dark.hover,
            padding: 7,
            paddingRight: 40,
            paddingLeft: 40,
            borderRadius: 4,
          }}
        >
          OPTIONS
        </div>
      </div>
    </div>
  );
}
