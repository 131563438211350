import React, { useState } from "react";
import Backend from "../../../../Backend/Backend";
import { Dark } from "../../../../Theme/Theme";
import { toast } from "react-toast";
import "../Header.css";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import Loading from "react-loading";
import moment from "moment";
const bakend = new Backend();

export default function AddSymbol(props) {
  const [loading, setLoading] = useState(false);

  const [date, setDate] = useState(null);
  const [selectedmonth, setSelectedMonth] = useState(null);
  const [selectedyear, setSelectedYear] = useState(null);
  const [symbol, setSymbol] = useState(null);
  const [type, setType] = useState(null);
  const [ltp, setLtp] = useState(null);

  const month_list = [
    { name: "January", value: "JAN" },
    { name: "February", value: "FEB" },
    { name: "March", value: "MAR" },
    { name: "April", value: "APR" },
    { name: "May", value: "MAY" },
    { name: "June", value: "JUN" },
    { name: "July", value: "JUL" },
    { name: "August", value: "AUG" },
    { name: "September", value: "SEP" },
    { name: "October", value: "OCT" },
    { name: "November", value: "NOV" },
    { name: "December", value: "DEC" },
  ];
  const year_list = [
    { name: "24", value: "24" },
    { name: "25", value: "25" },
    { name: "26", value: "26" },
    { name: "27", value: "27" },
    { name: "28", value: "28" },
    { name: "29", value: "29" },
    { name: "30", value: "30" },
    { name: "31", value: "31" },
    { name: "32", value: "32" },
    { name: "33", value: "33" },
    { name: "34", value: "34" },
  ];
  const symbol_list = [
    { name: "NIFTY", value: "NIFTY" },
    { name: "BANKNIFTY", value: "BANKNIFTY" },
  ];
  const type_list = [
    { name: "CE", value: "CE" },
    { name: "PE", value: "PE" },
  ];

  const add_symbol = () => {
    if (date == null) {
      toast.error("Select Month First", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
    } else if (selectedmonth == null) {
      toast.error("Select Month First", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
    } else if (selectedyear == null) {
      toast.error("Select Year First", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
    } else if (symbol == null) {
      toast.error("Select Symbol First", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
    } else if (type == null) {
      toast.error("Select Type First", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
    } else if (ltp == null || ltp == "") {
      toast.error("Enter Ltp First", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
    } else {
      setLoading(true);
      let data = {
        token: localStorage.getItem("token"),
        id: localStorage.getItem("id"),
        username: localStorage.getItem("username"),
        server: localStorage.getItem("server"),
        date: date,
        month: selectedmonth,
        year: selectedyear,
        symbol: symbol,
        type: type,
        ltp: ltp,
      };

      bakend.symbol_add_options(data).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          props.close();
          toast.success(r.message, {
            backgroundColor: Dark.buy,
            color: Dark.text,
          });
        } else {
          toast.error(r.message, {
            backgroundColor: Dark.sell,
            color: Dark.text,
          });
        }
      });
    }
  };

  return (
    <div>
      <div
        style={{
          margin: 10,
          padding: 10,
          marginTop: 20,
          backgroundColor: Dark.background,
          height: 55,
          borderRadius: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ flex: 0.6, color: Dark.text, fontSize: 15 }}>
          Date : {date}
        </div>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <div className="loginInputSymbolAdd">
              <InputText
                value={date}
                onChange={(e) => {
                  setDate(e.target.value);
                }}
                type="number"
                placeholder="Enter Date"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          margin: 10,
          padding: 10,
          marginTop: 20,
          backgroundColor: Dark.background,
          height: 55,
          borderRadius: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ flex: 0.6, color: Dark.text, fontSize: 15 }}>
          MONTH : {selectedmonth}
        </div>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <Dropdown
              value={selectedmonth}
              onChange={(e) => setSelectedMonth(e.value)}
              options={month_list}
              optionLabel="name"
              placeholder="Select a Month"
            />
          </div>
        </div>
      </div>
      <div
        style={{
          margin: 10,
          padding: 10,
          marginTop: 20,
          backgroundColor: Dark.background,
          height: 55,
          borderRadius: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ flex: 0.6, color: Dark.text, fontSize: 15 }}>
          YEAR : {selectedyear}
        </div>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <Dropdown
              value={selectedyear}
              onChange={(e) => setSelectedYear(e.value)}
              options={year_list}
              optionLabel="name"
              placeholder="Select a Year"
            />
          </div>
        </div>
      </div>

      <div
        style={{
          margin: 10,
          padding: 10,
          marginTop: 20,
          backgroundColor: Dark.background,
          height: 55,
          borderRadius: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ flex: 0.6, color: Dark.text, fontSize: 15 }}>
          SYMBOL : {symbol}
        </div>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <Dropdown
              value={symbol}
              onChange={(e) => setSymbol(e.value)}
              options={symbol_list}
              optionLabel="name"
              placeholder="Select a Symbol"
            />
          </div>
        </div>
      </div>

      <div
        style={{
          margin: 10,
          padding: 10,
          marginTop: 20,
          backgroundColor: Dark.background,
          height: 55,
          borderRadius: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ flex: 0.6, color: Dark.text, fontSize: 15 }}>
          TYPE : {type}
        </div>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <Dropdown
              value={type}
              onChange={(e) => setType(e.value)}
              options={type_list}
              optionLabel="name"
              placeholder="Select a Type"
            />
          </div>
        </div>
      </div>

      <div
        style={{
          margin: 10,
          padding: 10,
          marginTop: 20,
          backgroundColor: Dark.background,
          height: 55,
          borderRadius: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ flex: 0.6, color: Dark.text, fontSize: 15 }}>
          LTP : {ltp}
        </div>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <div className="loginInputSymbolAdd">
              <InputText
                value={ltp}
                onChange={(e) => {
                  setLtp(e.target.value);
                }}
                type="number"
                placeholder="Enter Ltp Of Symbol"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        onClick={loading ? null : add_symbol}
        style={{
          padding: 10,
          backgroundColor: Dark.primary,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 20,
          paddingLeft: 30,
          paddingRight: 30,
          borderRadius: 7,
          color: Dark.text,
          marginTop: 20,
        }}
      >
        {loading ? (
          <div>
            <Loading type="spin" color={Dark.text} height={20} width={20} />
          </div>
        ) : (
          "SAVE"
        )}
      </div>
    </div>
  );
}
