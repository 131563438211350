import React from "react";
import { Dark } from "../../Theme/Theme";

export default function Menu() {
  return (
    <div
      style={{
        color: Dark.text,
        height: 40,
        margin: 10,
        backgroundColor: Dark.primary,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 10,
        paddingLeft: 20,
        paddingRight: 10,
      }}
    >
      <div style={{ flex: 1 }}>NAME</div>
      <div style={{ flex: 1,textAlign: "center" }}>SYMBOL-ID</div>
      <div
        style={{
          height: 40,
          width: 90,
          primaryColor: Dark.background,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 10,
          marginRight: 20,
        }}
      ></div>
    </div>
  );
}
