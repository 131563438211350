import React, { useState } from "react";
import Backend from "../../../../Backend/Backend";
import { Dark } from "../../../../Theme/Theme";
import { toast } from "react-toast";
import "../Header.css";
import { Dropdown } from "primereact/dropdown";

import Loading from "react-loading";
const bakend = new Backend();

export default function AddSymbol(props) {
  const [loading, setLoading] = useState(false);

  const add_symbol = () => {
    setLoading(true);
    bakend.symbol_add_cash().then((r) => {
      setLoading(false);
      if (r.error == "False") {
        props.close();
        toast.success(r.message, {
          backgroundColor: Dark.buy,
          color: Dark.text,
        });
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div>
      <div
        onClick={loading ? null : add_symbol}
        style={{
          padding: 10,
          backgroundColor: Dark.primary,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 20,
          paddingLeft: 30,
          paddingRight: 30,
          borderRadius: 7,
          color: Dark.text,
          marginTop: 20,
        }}
      >
        {loading ? (
          <div>
            <Loading type="spin" color={Dark.text} height={20} width={20} />
          </div>
        ) : (
          "ADD SYMBOL"
        )}
      </div>
    </div>
  );
}
