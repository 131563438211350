import React, { useState } from "react";
import { Dark } from "../../Theme/Theme";
import moment from "moment";
import { toast } from "react-toast";
import { confirmAlert } from "react-confirm-alert";
import Backend from "../../Backend/Backend";

const backend = new Backend()
export default function SymbolCard(props) {
  const item = props.item;

  const [loading, setLoading] = useState(false);

  const symbol_options_delete = () => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to delete mcx symbol.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setLoading(true);
            let data = {
              symbol_id: item.id,
            };

            backend.symbol_options_delete_all(data).then((r) => {
              setLoading(false);
              if (r.error == "False") {
                toast.success(r.message, {
                  backgroundColor: Dark.buy,
                  color: Dark.text,
                });
                props.load();
              } else {
                toast.error(r.message, {
                  backgroundColor: Dark.sell,
                  color: Dark.text,
                });
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => console.log("Click No"),
        },
      ],
    });
  };

  return (
    <div
      style={{
        color: Dark.text,
        height: 55,
        margin: 10,
        backgroundColor: Dark.secondary,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 10,
        paddingLeft: 10,
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 2,
        borderBottomColor: Dark.hover,
      }}
    >
      <div style={{ flex: 1 }}>
        <div style={{ fontWeight: "bold" }}>{item.symbol}</div>
        <div
          style={{
            fontSize: 12,
            paddingTop: 5,
            color: Dark.sell,
            fontWeight: "500",
          }}
        ></div>
      </div>
      <div style={{ flex: 1, textAlign: "center", fontWeight: "600" }}>
        {moment(item.expiry_date).format("YYYY-MM-DD HH:mm:ss")}
      </div>
      <div
        onClick={loading ? null : symbol_options_delete}
        style={{
          height: 40,
          width: 90,
          backgroundColor: Dark.sell,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 10,
          marginRight: 20,
          fontWeight: "600",
          opacity : loading ? 0.5 : 1
        }}
      >
        DELETE
      </div>
    </div>
  );
}
