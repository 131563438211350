import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";

import "react-confirm-alert/src/react-confirm-alert.css";

import './App.css'

import Cash from "./Symbol/Cash/Cash";
import Future from "./Symbol/Future/Future";
import Mcx from "./Symbol/Mcx/Mcx";
import Options from "./Symbol/Options/Options";
import { Dark } from "./Theme/Theme";

import Header from "./Symbol/Header";
import { useState } from "react";

function App() {
  const [type, setType] = useState(0);

  return (
    <div
      className="App"
      style={{ height: window.innerHeight, backgroundColor: Dark.background }}
    >
      <Header type={type} update={(e) => setType(e)} />
      {type == 0 ? <Cash /> : null}
      {type == 1 ? <Future /> : null}
      {type == 2 ? <Mcx /> : null}
      {type == 3 ? <Options /> : null}
    </div>
  );
}

export default App;
