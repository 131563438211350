// const api_link = "http://192.168.0.197:5004";
const api_link = "http://35.240.164.246:7004";
const EntryKeyGod = "74b80f6a42923f5839247665b0e8344d";

export default class Backend {

  symbol_add_cash(data) {
    return fetch(api_link + "/symbol_add_cash", {
      method: "POST",
      headers: {
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  symbol_get_cash(data) {
    return fetch(api_link + "/symbol_get_cash", {
      method: "POST",
      headers: {
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  symbol_cash_delete(data) {
    return fetch(api_link + "/symbol_cash_delete", {
      method: "POST",
      headers: {
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  symbol_add_future(data) {
    return fetch(api_link + "/symbol_add_future", {
      method: "POST",
      headers: {
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  symbol_get_future(data) {
    return fetch(api_link + "/symbol_get_future", {
      method: "POST",
      headers: {
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  symbol_future_delete_all(data) {
    return fetch(api_link + "/symbol_future_delete_all", {
      method: "POST",
      headers: {
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  symbol_add_mcx(data) {
    return fetch(api_link + "/symbol_add_mcx", {
      method: "POST",
      headers: {
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  symbol_get_mcx(data) {
    return fetch(api_link + "/symbol_get_mcx", {
      method: "POST",
      headers: {
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  symbol_mcx_delete(data) {
    return fetch(api_link + "/symbol_mcx_delete", {
      method: "POST",
      headers: {
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  symbol_add_options(data) {
    return fetch(api_link + "/symbol_add_options", {
      method: "POST",
      headers: {
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  symbol_get_options(data) {
    return fetch(api_link + "/symbol_get_options", {
      method: "POST",
      headers: {
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  symbol_options_delete_all(data) {
    return fetch(api_link + "/symbol_options_delete_all", {
      method: "POST",
      headers: {
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
}
